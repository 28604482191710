<template>
  <div class="list-user-token-page">
    <KTCodePreview v-bind:title="'Danh sách user token'">
      <template v-slot:toolbar>
        <div class="row">
          <div class="ml-3"></div>
        </div>
      </template>
      <template v-slot:preview>
        <!-- Header session -->
        <!-- tag-->
        <b-row class="mb-5">
          <b-col cols="4">
            <label style="font-weight: 500px">Tìm theo nhân sự:</label>
            <multiselect
              v-model="params.employeeId"
              placeholder="Tìm kiếm theo tên nhân sự"
              label="employeeName"
              track-by="employeeName"
              :options="listEmployee"
              :multiple="true"
              :taggable="true"
              :selectLabel="'Chọn nhân sự'"
              :deselectLabel="'Xoá nhân sự'"
            ></multiselect>
          </b-col>
        </b-row>
        <!--end tag-->
        <b-row class="mb-5">
          <b-col md="1">
            <b-button
              variant="primary"
              size="sm"
              class="btn font-weight-bold btn-size"
              @click="onFilter"
              :disabled="onLoading"
              >Lọc</b-button
            >
          </b-col>
        </b-row>
        <!-- End of Header session -->
        <!-- Table session -->
        <b-table
          :items="listUserToken"
          :fields="fields"
          bordered
          hover
          :busy="onLoading"
          @row-clicked="editItem"
        >
          <template v-slot:table-busy>
            <vcl-table :speed="5" :animate="true" :columns="10"></vcl-table>
          </template>
          <template v-slot:cell(createdAt)="row">
            {{ formatDate(row.item.createdAt, DATE_FORMAT) }}
          </template>
          <template v-slot:cell(lastLoginDate)="row">
            {{
              row.item.lastLoginDate &&
              formatDate(row.item.lastLoginDate, DATE_FORMAT)
            }}
          </template>
          <template v-slot:cell(isActiveName)="row">
            <span :class="getBadgeClassName(row.item.isActive)">{{
              row.item.isActiveName
            }}</span>
          </template>

          <template v-slot:cell(actions)="row">
            <div class="d-flex justify-content-center">
              <b-dropdown size="sm" id="dropdown-left" no-caret right>
                <template slot="button-content">
                  <i class="flaticon2-settings"></i>
                </template>
                <b-dropdown-item
                  v-if="checkPermission('USER_TOKEN_VIEW')"
                  @click="editItem(row.item)"
                >
                  <span style="color: #3f4254; font-size: 10px">
                    <i class="flaticon2-pen icon-size"></i>
                    &nbsp; Chi tiết
                  </span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="checkPermission('USER_TOKEN_REVOKE')"
                  @click="
                    showDeleteAlert(
                      `${modalDeleteTitle} '${row.item.username}'`,
                      modalDeleteDesc,
                      row.item,
                      confirmRevokeAllUserToken,
                    )
                  "
                >
                  <span style="color: #3f4254; font-size: 10px">
                    <i class="fa-solid fa-clock-rotate-left text-danger"></i>
                    &nbsp; Thu hồi token
                  </span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
        <!-- End of Table session -->

        <!-- Paginate session -->
        <b-row>
          <b-col>
            <p class="mt-3 text-dark font-weight-bold">
              Tổng:
              {{ totalItems }}
            </p>
          </b-col>
          <b-col>
            <BPaginationNavCustom
              :number-of-pages="totalPages || 1"
              :current-page="params.page"
              :total-page="totalPages"
              :linkParams="{}"
              @page-change="fetchData()"
            >
            </BPaginationNavCustom>
          </b-col>
        </b-row>
        <!-- End of Paginate session -->
      </template>
    </KTCodePreview>
  </div>
</template>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import ApiService from '@/core/services/api.service';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { VclTable } from 'vue-content-loading';
import 'vue-cute-timeline/dist/index.css';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { formatDate, DATE_FORMAT, CLIENT_DATE_TIME } from '@/utils/date';
import { showDeleteAlert } from '@/utils/sweet-alert2';
import BPaginationNavCustom from '@/view/base/bootstrap/BPaginationNavCustom.vue';
import { checkPermission } from '@/utils/saveDataToLocal';
import { cmdUrl } from '@/utils/apiUrl';
import Multiselect from 'vue-multiselect';
import { IS_ACTIVE_COMMON } from '@/utils/enum';

export default {
  data() {
    return {
      linkParams: {},
      modalDeleteTitle: 'Thu hồi token tài khoản',
      modalDeleteDesc:
        'Bạn có chắc muốn thu hồi tất cả token của tài khoản này. Thao tác này bắt buộc tài khoản này phải đăng nhập lại!',
      CLIENT_DATE_TIME: CLIENT_DATE_TIME,
      DATE_FORMAT: DATE_FORMAT,
      onLoading: false,
      listUserToken: [],
      fields: [
        {
          key: 'username',
          label: 'Tài khoản',
          sortable: false,
          thStyle: {
            fontWeight: 600,
            color: '#181c32',
            width: '8%',
            textAlign: 'center',
          },
          tdClass: 'text-center',
        },
        {
          key: 'fullname',
          label: 'Tên nhân sự',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '20%' },
        },
        {
          key: 'lastLoginDate',
          label: 'Đăng nhập gần nhất',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'failedLoginCount',
          label: 'Số lần đăng nhập thất bại',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '15%' },
        },
        {
          key: 'createdAt',
          label: 'Ngày tạo',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '10%' },
        },
        {
          key: 'isActiveName',
          label: 'Trạng thái',
          sortable: false,
          thStyle: { fontWeight: 600, color: '#181c32', width: '8%' },
          tdClass: 'text-left',
        },
        {
          key: 'actions',
          label: '',
          sortable: false,
          thStyle: { width: '10%' },
        },
      ],
      linkGen: (pageNum) => {
        return pageNum === 1 ? '?' : `?page=${pageNum}`;
      },
      totalPages: 1,
      totalItems: 0,
      params: {
        page: 1,
        limit: 10,
        employeeId: [],
      },
      listEmployee: [],
    };
  },
  methods: {
    showDeleteAlert,
    formatDate,
    checkPermission,
    async confirmRevokeAllUserToken(item) {
      const endPoint = cmdUrl.userToken.revokeAllToken.replace(
        '{userId}',
        item.id,
      );
      ApiService.put(endPoint)
        .then(({ data }) => {
          makeToastSuccess(data?.message);
        })
        .catch((error) => {
          this.makeToastErrorMessage(error);
        });
    },
    editItem(item) {
      this.$router.push({
        name: 'info-user-token',
        query: {
          userId: item.id,
        },
      });
    },
    onFilter() {
      this.$route.query.page = 1;
      this.$router.push({
        name: 'list-user-token',
      });
      this.fetchData();
    },
    setParamFilter() {
      const employeeId = this.params.employeeId.map(
        ({ employeeId }) => employeeId,
      );
      const page = this.$route.query.page || 1;
      return {
        ...this.params,
        page,
        employeeId: employeeId.length ? employeeId : null,
      };
    },
    fetchData: async function () {
      try {
        if (this.onLoading) return;
        this.onLoading = true;
        const params = this.setParamFilter();
        const {
          data: { data },
        } = await ApiService.query(cmdUrl.userToken.root, {
          params,
        });
        this.listUserToken = data.result;
        this.totalItems = data.totalItems;
        this.totalPages = data.totalPages;
      } catch (error) {
        this.makeToastErrorMessage(error);
      } finally {
        this.onLoading = false;
      }
    },
    makeToastErrorMessage(error) {
      const message =
        error.response?.data?.message ?? error.message ?? 'Network error';
      makeToastFaile(message);
    },
    async getEmployee() {
      const {
        data: { data },
      } = await ApiService.query('employees/getAll');
      this.listEmployee = data.map((item) => {
        return {
          employeeId: item.id,
          employeeName: item.fullName,
        };
      });
    },
    getBadgeClassName(active) {
      if (active !== IS_ACTIVE_COMMON.TRUE) {
        return 'badge badge-danger';
      }
      return 'badge badge-success';
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Cấu hình user token', route: 'user-token' },
      { title: 'Danh sác user token' },
    ]);
  },
  created() {
    this.fetchData();
    this.getEmployee();
  },
  components: {
    KTCodePreview,
    VclTable,
    BPaginationNavCustom,
    Multiselect,
  },
};
</script>

<style scoped>
.btn-size {
  width: 90px;
}

.icon-size {
  font-size: 1rem;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: middle;
  border-top: 1px solid #ecf0f3;
}

input:focus {
  outline: none !important;
  border: 1px solid #92c5f9;
}

.icon:hover {
  background-color: #90c6fc;
}
</style>
<style lang="scss" scoped>
.list-user-token-page {
  .dropdown-item-text {
    font-size: 12px;
    color: #3f4254;
  }
}
</style>
